<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework update report</h3>


    <p>The Framework Update Report can be accessed by any signed in user via the HAMBURGER MENU in the top right corner of the screen. The report can be generated for all frameworks in the <span v-html="app_name"/>  instance and has options to drill in to view archives (versions) and to track changes. The information presented in the report is also visible when navigating frameworks via the metadata icon and change history button as well as via the API.
    </p>

    <img srcset="/docimages/framework_update_report.png 1.1x" class="k-help-img float-right">

    <p class="k-no-clear">From the <strong> Map, Tile,</strong> or <strong>Tree</strong> view of the Framework Index, generating the Framework Update Report will display all available frameworks (based on the user’s role and permissions). User’s can:
        <ol>
            <li>Determine the <strong>UPDATE TYPES TO SHOW</strong> for <strong>Items, Associations</strong> (if applicable) and the <strong>Document.</strong></li>
            <li>Quickly <strong>search</strong> the report for a particular framework</li>
            <li><Strong>Sort</Strong> the report by any column header.</li>
            <li>Select the <strong>drill in</strong> <nobr><v-icon small>fa-solid fa-arrow-turn-down</v-icon> SAVE CHANGES</nobr> icon to open the archive history or <strong>track changes</strong> view for the selected framework to learn more about what changed.</li>
        </ol>
    </p>
    <br>
    <br>

    <p class="k-no-clear"><strong>Important Tip for API users:</strong> The GUID for an item will not change if an item has minor text updates, spelling errors corrected, notes added, etc. Use the filter for UPDATE TYPES TO SHOW to find frameworks where items were moved, added, or deleted.</p>
    <img srcset="/docimages/framework_update_items.png 1.6x" class="k-help-img block">


	
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>